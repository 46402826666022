//@import "../hiv_risk_calculator/stylesheets/base/_variables.scss";
//@import "../hiv_risk_calculator/stylesheets/grid.scss";
$gutter-size: 1em;

// Default grid
$neat-grid: (
  columns: 12,
  gutter: $gutter-size
);

// Bootstrap media queries...
$mobile-size: 576px;
$tablet-size: 768px;
$desktop-size: 992px;
$large-desktop-size: 1200px;

// ...into Bourbon Neat breakpoints
$mobile-grid: (
  columns: 4,
  media: 0
);
$tablet-grid: (
  columns: 8,
  media: $tablet-size + 1
);
$desktop-grid: (
  media: $desktop-size
);
$large-desktop-grid: (
  columns: 16,
  media: $large-desktop-size
);

// Theme Variables
$primary-color: #0f2a65;
$primary-fill: white;
$border-gray: #efefef;

$thin-outline: 1px solid $border-gray;
$medium-outline: 2px solid darken($border-gray, 20%);
$medium-thick-outline: 2.5px solid darken($border-gray, 40%);
$thick-outline: 3px solid $primary-color;

$rounded-square-radius: 0.5em;
$square-padding: 0.2em;

$circle-radius: 5em;
// Breakpoints
$medium-screen: 600px;
$large-screen: 900px;

// Typography
$base-font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: calc($base-spacing / 2);
$base-z-index: 0;

// Colors
$blue: #0f2a65;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $blue;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: lighten($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus:
  $form-box-shadow,
  0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;

#sti-risk-calculator-tool {
  #calculator {
    border: $thick-outline;
    border-radius: $rounded-square-radius;
    width: 768px;
    margin: auto;

    button {
      border: $thin-outline;
      background-color: $secondary-background-color;
      border-radius: $rounded-square-radius;
      padding: $square-padding;

      &.active {
        background-color: $action-color;
      }
    }

    img,
    picture {
      margin: 0;
      max-width: 100%;
    }

    #column_holder {
      display: flex;
      border: 1px solid #0f2a65;
      justify-content: space-around;
      word-break: break-word;
      .key-header {
        font-size: 1.5em;
      }
      .key-text {
        font-size: 1.25em;
      }
      @media only screen and (max-width: 768px) {
        .key-text {
          font-size: 0.9em;
        }
      }
      div {
        flex: 1;
        span {
          font-size: 1.5em;
        }

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1em;
          @media only screen and (max-width: 768px) {
            font-size: 0.75em;
            margin-left: 10%;
            margin-right: 10%;
          }
          margin-left: 15%;
          margin-right: 15%;
          margin-bottom: 5px;
          margin-top: 5px;

          img {
            width: 20%;
            align-self: center;
            @media only screen and (max-width: 768px) {
              width: 30%;
            }
            @media only screen and (max-width: 480px) {
              width: 25%;
            }
          }
        }
      }
    }
  }

  #factor-group {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(100px, 1fr) 5fr;

    #label {
      border-right: $thin-outline;
      h3 {
        text-transform: capitalize;
        margin: $square-padding;
      }
    }

    #factors {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: $square-padding;
    }
  }

  .bodypart-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: $square-padding;
  }

  .keypad {
    border-bottom: $medium-outline;
    align-items: center;
    display: grid;
    grid-template-columns: minmax(100px, 1fr) 5fr;
  }

  .sex-group {
    border-right: $thin-outline;
    padding: $square-padding;

    h3 {
      text-transform: capitalize;
      margin: $square-padding;
      text-align: center;

      small {
        margin: auto;
      }
    }

    .sexes {
      max-width: 8em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $square-padding;
      gap: 5px;
    }
  }

  #risk {
    border-top: $medium-thick-outline;
    padding: $square-padding;
    padding-top: $square-padding * 2;
    text-align: center;

    h3 {
      margin-bottom: $square-padding;
    }
    h4 {
      margin-bottom: auto;
    }
    p {
      margin-bottom: auto;
    }

    #riskBar {
      height: $base-font-size * 3;
      border-radius: $base-border-radius;
      display: flex;
      z-index: 1;
      border: $medium-outline;
      position: relative;

      .sliders {
        width: 0.5em;
        height: 125%;
        position: absolute;
        left: 0;
        top: calc(-12.5%);
        transition: 1s;
        border-radius: $base-border-radius;
        opacity: 0;
      }

      .bars {
        height: 100%;
        z-index: -1;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: white;
        transition: 1s;
      }

      .arrow {
        opacity: 0;
        border-top: 0.25em solid white;
        border-bottom: 0.25em solid white;
        top: calc(50% - 0.25em);
        position: absolute;
        transition: 1s;

        &.left:after {
          content: "";
          border-top: 1em solid transparent;
          border-right: 1em solid white;
          border-bottom: 1em solid transparent;
          position: absolute;
          top: -1em;
          left: -1em;
        }
        &.right:after {
          content: "";
          border-top: 1em solid transparent;
          border-left: 1em solid white;
          border-bottom: 1em solid transparent;
          position: absolute;
          top: -1em;
          right: -1em;
        }
      }
    }
  }

  #regular {
    font-size: 1.25em;
    width: 100%;
    justify-content: space-around;
    background: linear-gradient(to right, #4cd964, #ffcc00, #ff3b30);

    &.fullbar {
      font-size: 1em;
      width: 35%;
    }
  }

  #gradient {
    font-size: 0;
    width: 0;
    justify-content: center;
    background: linear-gradient(to right, #960800, #4b0400);

    &.fullbar {
      font-size: 1.25em;
      width: 65%;
    }
  }
}
