// Theme Variables

$primary-color: #0f2a65;
$primary-fill: white;
$border-gray: #efefef;
$favorite-color: #ff5f5f;
$error-color: $favorite-color;

$thin-outline: 1px solid $border-gray;
$thick-outline: 3px solid $primary-color;

$rounded-square-radius: 0.5em;
$square-padding: 0.2em;

$circle-radius: 5em;

$xxl-circle: 5.77em;
$xl-circle: 5.13em;
$l-circle: 4.56em;
$m-circle: 4.05em;
$s-circle: 3.6em;
$xs-circle: 3.2em;
$xxs-circle: 2.844em;

// Bootstrap media queries...
$mobile-size: 576px;
$tablet-size: 768px;
$desktop-size: 992px;
$large-desktop-size: 1200px;

$gutter-size: 1em;

// Breakpoints
$medium-screen: 600px;
$large-screen: 900px;

$base-border-radius: 3px;
$base-line-height: 1.5;
$base-spacing: $base-line-height * 1em;
$small-spacing: calc($base-spacing / 2);

// Colors
$blue: #0f2a65;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
