/* for honeypot captcha, to hide offscreen (STI locator) */
.hon {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
