// Theme Shared Styles

@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin row-spacing($gutter) {
  margin-top: $gutter;
  margin-bottom: $gutter;
}

@mixin thin-circle {
  border: $thin-outline;
  border-radius: $circle-radius;
  background-color: $primary-fill;
}

@mixin thick-circle {
  border: $thick-outline;
  border-radius: $circle-radius;
  background-color: $primary-fill;
}

@mixin thin-square {
  border: $thin-outline;
  border-radius: $rounded-square-radius;
  background-color: $primary-fill;
}

@mixin thick-square {
  border: $thick-outline;
  border-radius: $rounded-square-radius;
  background-color: $primary-fill;
}

@mixin concentric-circle($size) {
  @include size($size);

  position: absolute;

  > div {
    @include size($size * calc(8 / 9));

    margin-top: 4.5%;
    margin-left: 4.5%;
    position: absolute;
  }
}

@mixin circle-centered-title {
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: absolute;
    left: 0;
    margin-left: -1%;
    margin-top: 0;
    width: 100%;
  }
}

@mixin flex-centered-h {
  align-items: center;
  display: flex;
  flex-direction: column;
}
