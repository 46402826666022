@use "@material/button";

.relationship_quizzes {
  --mdc-typography-button-text-transform: none;

  .container {
    margin: 0 auto;

    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }

  #relationship-quiz {
    margin-top: 20px;
  }

  .hidden {
    display: none !important;
  }

  .text-center {
    text-align: center;
  }

  .multiple-choice {
    .mdc-button {
      width: 100%;
    }
  }

  .quiz-btn {
    white-space: unset;

    @include button.filled-accessible(#6c757d);
  }

  .main-gif,
  .serious,
  .casual,
  .single-looking,
  .single-happy {
    max-width: 95%;
    display: block;
    margin: 0 auto;
  }

  #start-button {
    display: block;
    margin: 0 auto;
    margin-top: 0.5rem;

    @include button.filled-accessible(#0d6efd);
  }
}
