$gutter-size: 1em;

// Default grid
$neat-grid: (
  columns: 12,
  gutter: $gutter-size
);

// Bootstrap media queries...
$mobile-size: 576px;
$tablet-size: 768px;
$desktop-size: 992px;
$large-desktop-size: 1200px;

// ...into Bourbon Neat breakpoints
$mobile-grid: (
  columns: 4,
  media: 0
);
$tablet-grid: (
  columns: 8,
  media: $tablet-size + 1
);
$desktop-grid: (
  media: $desktop-size
);
$large-desktop-grid: (
  columns: 16,
  media: $large-desktop-size
);

// Theme Variables
$primary-color: #0f2a65;
$primary-fill: white;
$border-gray: #efefef;

$thin-outline: 1px solid $border-gray;
$medium-outline: 2px solid shade($border-gray, 20%);
$medium-thick-outline: 2.5px solid shade($border-gray, 40%);
$thick-outline: 3px solid $primary-color;

$rounded-square-radius: 0.5em;
$square-padding: 0.2em;

$circle-radius: 5em;
