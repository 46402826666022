$selected: #01941a;
$unselected: #1d62e1;
$kiu_light: #1492a7;
$kiu_dark: #0f2a66;
$kiu_extra_light: #45c3d8;
$mobile-width-max: 599px;

#kiu-goal-setting {
  width: 100%;
  text-align: center;

  .mdc-text-field--outlined {
    width: 80%;
  }

  .mdc-text-field--focused,
  .mdc-floating-label--float-above {
    --mdc-theme-primary: #01941a;
    color: $selected;
  }

  .goals {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;

    .goal-title,
    .new-goal-title {
      width: 40%;
      border-radius: 5px;
      margin-top: 1.5rem;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bolder;

      .fa-check {
        font-size: larger;
        margin-right: 15px;
        -webkit-text-stroke: 2px $selected;
        justify-self: flex-start;
      }
    }

    @media (max-width: $mobile-width-max) {
      .goal-title {
        width: 100%;
      }
    }

    .goal-title:last-of-type {
      margin: auto;
      margin-top: 1.5rem;
    }

    .outline-selected {
      border: solid 5px $selected;
      color: $selected;
    }

    .outline-unselected {
      border: solid 5px $unselected;
      color: $unselected;
    }
  }

  .goal-submit-div {
    margin-top: 32px;

    .mdc-button {
      --mdc-theme-primary: #1d62e1;
    }
  }

  .mdc-button {
    --mdc-theme-primary: #1492a7;
  }
}

#kiu-goal-setting-follow-up {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;

  h1 {
    text-align: center;
  }

  .multistep {
    // .form-step {
    //   position: relative;
    // }

    .form-step:not(:first-of-type) {
      display: none;
    }
  }

  #progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    padding: 0;
    /*CSS counters to number the steps*/
    counter-reset: step;

    li {
      list-style-type: none;
      text-align: center;
      font-size: 0.75rem;
      width: 33.33%;
      float: left;
      position: relative;
      letter-spacing: 1px;

      &:before {
        content: counter(step);
        counter-increment: step;
        width: 24px;
        height: 24px;
        line-height: 24px;
        display: block;
        color: #333;
        background: lightGrey;
        border-radius: 25px;
        margin: 0 auto 10px auto;
      }

      /*progressbar connectors*/
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: lightGrey;
        position: absolute;
        left: -50%;
        top: 11px;
        z-index: -1; /*put it behind the numbers*/
      }

      &:first-child:after {
        /*connector not needed before the first step*/
        content: none;
      }

      &.active {
        color: $kiu_light;
      }

      &.activated {
        /*marking active/completed steps*/
        /*The number of the step and the connector before it*/
        &:before,
        &:after {
          background: $kiu_light;
          color: white;
        }
      }
    }
  }

  .input-step {
    display: flex;
    flex-direction: column;

    label {
      width: 100%;
    }
  }

  .image-container {
    text-align: center;
  }

  nav {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .mdc-button {
      background-color: $kiu_light;
    }
  }
}

.kiu_goal_settings#goal_summary {
  padding: 0 40px;

  h1 {
    text-align: center;
  }

  h2 {
    font-size: xx-large;
    margin-bottom: 16px;
  }

  form {
    display: flex;
    flex-direction: column;

    .mdc-text-field {
      width: 40%;
    }

    .mdc-button {
      --mdc-theme-primary: #1d62e1;
    }
  }
}

.kiu_goal_settings#show {
  margin-bottom: 10rem;

  .directions {
    margin: 16px 64px;
  }
}

@media (max-width: $mobile-width-max) {
  .kiu_goal_settings#show {
    margin-bottom: 12rem;

    .directions {
      font-size: larger;
      margin: 16px;
    }
    .goal-submit-div {
      p {
        font-size: larger;
        margin: 16px;
      }

      .mdc-button {
        width: 80%;
      }
    }
  }
}
