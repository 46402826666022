@import "packs/mixins/template_items";
@import "packs/mixins/template_variables";

$tablet-width: 900px;

/* Styles for the Muliple Choice Question pages. */
@mixin concentric-label-sizes($outer, $inner, $font, $line-height) {
  @include size($outer);

  .label {
    @include size($inner);

    line-height: $line-height;
    font-size: $font;
  }
}

@mixin square-border {
  display: none;

  &.visible {
    display: initial;
  }

  &.correct div {
    border-color: green;
  }

  &.incorrect div {
    border-color: red;
  }

  div {
    padding: $square-padding;
    @include thick-square;
  }
}

label {
  display: inline-block;
}

.display-none {
  display: none !important;
}

.goal-setting-bolded-title {
  font-weight: bold;
  background-color: lightblue;
  padding: 10px;
  width: 50%;
  margin: auto;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 10px;
  line-height: 2rem;
}

#smart-goal-setting,
#smart-goal-selection,
#smart-goal-setting-booster-one,
#smart-goal-setting-booster-one-barriers,
#smart-goal-setting-booster-two {
  input[type="submit"],
  button[type="submit"] {
    display: block;
    margin: 0 auto;
    bottom: 15%;
    position: relative;
    padding: 0.5em;
  }

  .submit {
    text-align: center;
    margin-top: 2em;
  }

  .title-wrapper {
    h2 {
      font-weight: bold;
      text-align: center;
      margin-top: 1em;
    }
  }

  .multiple-choice {
    display: grid;
    gap: $gutter-size;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
    .answer {
      padding: $square-padding;
      min-height: 7.5em;

      @include thin-square;

      position: relative;
      margin-bottom: 0.5em;

      label.selected {
        border: 5px solid green;
      }

      .label-circle {
        $label-scale: 0.7;
        @include concentric-circle($s-circle * $label-scale);
        @include thin-circle;

        top: -0.5em;
        left: -0.5em;

        @include concentric-label-sizes(
          $m-circle * $label-scale,
          $s-circle * $label-scale,
          1em,
          2.2em
        );

        @media (min-width: $tablet-width) {
          @include concentric-label-sizes(
            $l-circle,
            $l-circle * $label-scale,
            1.27em,
            3em
          );
        }

        @media (min-width: $large-desktop-size) {
          @include concentric-label-sizes(
            $xxl-circle,
            $m-circle * $label-scale,
            1.8em,
            2.7em
          );

          top: -0.6em;
          left: -0.6em;
        }

        .label {
          @include thick-circle;

          text-align: center;
          color: $primary-color;
        }
      }

      .button {
        @include size(100%);
        padding: $square-padding;
        cursor: pointer;
        @include thick-square;

        @media (min-width: $mobile-size) {
          padding-top: 2em;
        }

        @media (min-width: $tablet-width) {
          padding-left: 4em;
          padding-right: 4em;
        }

        @media (min-width: $desktop-size) {
          padding-left: 5em;
          padding-right: 5em;
        }

        font-size: 1em;
        text-align: center;
        line-height: normal;

        span {
          display: inline-block;
          vertical-align: middle;
          line-height: normal;
          hyphens: auto;
        }

        &.selected-multi {
          border-color: blue;

          .label {
            border-color: blue;
          }
        }

        &.selected {
          .label {
            background-size: 70%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}

#goal-setting-activity-self-efficacy-assessment,
#smart-booster-choose-goal-progress,
#smart-booster-two-choose-goal-progress {
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  th {
    text-align: inherit;
  }

  table {
    margin: 25px;
    caption-side: bottom;
    border-collapse: collapse;
  }
  thead th {
    text-align: center;
  }
  th {
    width: 25%;
    padding-right: 15px;
  }
  tr {
    border-top: 1px solid lightgray;
  }
  .goal-label {
    padding-right: 15px;
  }
  td {
    text-align: center;
  }

  @media (max-width: $mobile-size) {
    table {
      margin: 0;

      th {
        font-size: small;
      }
    }
  }
}

input {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}

ul.strategy-list {
  list-style-type: none;
  padding-right: 2rem;
}

ol,
ul {
  p {
    padding-right: 2rem;
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
}

.mdc-button {
  background-color: #0d6efd !important;
}

.booster-wrap-up-page {
  padding-bottom: 3rem;

  .send-email-button {
    height: 40px !important;
    margin-bottom: 0;
  }

  .mdc-dialog__actions {
    align-items: flex-end;
    button {
      text-transform: none;
      background-color: #fd0d0d;
      color: white;
      margin-right: 0.5rem;
      height: fit-content;
      padding: inherit;
      margin-top: 24px;
    }
  }
}

@media (max-width: $tablet-width) {
  .submit-button-div {
    text-align: center;
  }

  .submit-button {
    scale: 1.25;
    width: fit-content;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  #custom-goal {
    display: flex;
    justify-content: center;
  }

  .booster-wrap-up-page {
    #email-dialog-button {
      width: 60%;
      height: fit-content;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .btn-primary {
    margin-top: 24px !important;
  }

  .smart-goal-setting-email-form {
    display: flex;
    .email-button {
      margin-top: 0 !important;
      margin-left: 1rem;
    }
  }
}

@media (max-width: $mobile-size) {
  label.button {
    display: flex;
    align-items: center;
  }

  .booster-wrap-up-page {
    button {
      height: 3rem;
    }
  }

  .goal-setting-bolded-title {
    width: 75%;
  }

  .submit-button {
    scale: 1.25;
    margin-bottom: 2rem !important;
    padding: 0.5em !important;
  }

  .smart-goal-setting-email-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    input {
      width: 80%;
    }
    .email-button {
      margin: 1rem !important;
      width: fit-content;
      scale: 1.25;
    }
  }
}

.booster-wrap-up-page {
  .mdc-touch-target-wrapper {
    display: block;
    text-align: center;
  }
}

.submit-button-div {
  width: 100%;
  margin-bottom: 1rem;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;

  .toast:not(.show) {
    display: none;
  }

  .toast {
    --bs-toast-zindex: 1090;
    --bs-toast-padding-x: 0.75rem;
    --bs-toast-padding-y: 0.5rem;
    --bs-toast-spacing: 1.5rem;
    --bs-toast-max-width: 350px;
    --bs-toast-font-size: 0.875rem;
    --bs-toast-color: ;
    --bs-toast-border-width: 1px;
    --bs-toast-border-color: var(--bs-border-color-translucent);
    --bs-toast-border-radius: 0.375rem;
    --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-toast-header-color: #6c757d;
    --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
    --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
    width: var(--bs-toast-max-width);
    max-width: 100%;
    font-size: var(--bs-toast-font-size);
    color: var(--bs-toast-color);
    pointer-events: auto;
    background-color: #0dcaf0;
    background-clip: padding-box;
    border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
    box-shadow: var(--bs-toast-box-shadow);
    border-radius: var(--bs-toast-border-radius);

    .toast-body {
      padding: 0.75rem;
      word-wrap: break-word;

      button.btn-close {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        padding: 0.25em 0.25em;
        color: #000;
        background: transparent
          url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
          center/1em auto no-repeat;
        border: 0;
        border-radius: 0.375rem;
        opacity: 0.5;
      }
    }
  }
}

.show-submit-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.position-absolute {
  position: fixed !important;
}

.bottom-0 {
  bottom: 3rem !important;
}

.start-50 {
  left: 50% !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}
