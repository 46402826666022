[role="tooltip"] {
  .tooltip-content {
    /* triangle dimension */
    --b: 2em; /* base */
    --h: 1em; /* height */

    --p: 50%; /* triangle position (0%:left 100%:right) */
    --border-radius: 4px; /* the radius */
    --background-color: #0c0c0c;
    --color: #fff;

    padding: 1em;
    border-radius: min(var(--border-radius), var(--p) - var(--b) / 2)
      min(var(--border-radius), 100% - var(--p) - var(--b) / 2)
      var(--border-radius) var(--border-radius) / var(--border-radius);
    clip-path: polygon(
      0 0,
      0 100%,
      100% 100%,
      100% 0,
      min(100%, var(--p) + var(--b) / 2) 0,
      var(--p) calc(-1 * var(--h)),
      max(0%, var(--p) - var(--b) / 2) 0
    );
    background: var(--background-color);
    border-image: conic-gradient(var(--background-color) 0 0) fill 0/ 0 calc(
        100% - var(--p) - var(--b) / 2
      ) var(--border-radius) calc(var(--p) - var(--b) / 2) / var(--h) 0 0 0;

    color: var(--color);
    font-weight: 500;
    max-width: 25ch;
    position: absolute;
    z-index: 3;
    top: 1em;
  }
}

[role="tooltip"],
.hide-tooltip + [role="tooltip"] {
  visibility: hidden;
  position: relative;
}

[aria-describedby]:hover + [role="tooltip"],
[aria-describedby]:focus + [role="tooltip"] {
  visibility: visible;
}

.ionic-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  color: black;
  padding: 10px;
  padding-right: 50px;
  padding-left: 50px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
  transition:
    transform 0.3s ease,
    opacity 0.3s ease,
    visibility 0s linear 0.3s;
}

.ionic-banner.open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
}

.hide {
  display: none !important;
}

.input-label-placement-floating.sc-ion-input-md-h input.sc-ion-input-md {
  opacity: 1 !important;
}
