// Breakpoints
$large-screen: 900px;

// Line height
$base-line-height: 1.5;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: calc($base-spacing / 2);

#condom_locator_tool {
  p {
  }
  width: 100%;
  max-width: $large-screen;
  margin: auto;
  text-align: left;

  .title {
    font-size: 4rem;
    font-weight: normal;
    margin-top: 1rem;
    @media only screen and (max-width: 600px) {
      font-size: 2.5rem;
    }
  }

  #instructions {
    text-align: left;
    font-size: 2rem;
    line-height: 2rem;
    @media only screen and (max-width: 600px) {
      font-size: 1.5rem;
      font-weight: lighter;
      line-height: 1.5rem;
    }
  }

  #locator_container {
    display: flex;
    align-items: center;
    font-size: 0.75em;
    flex-direction: column;

    #question_list {
      width: 100%;
      border-radius: $base-border-radius * 2;
      border: 1px solid #333;
    }

    #place_list {
      text-align: center;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      div {
        width: 30%;
        padding: calc($base-spacing);
        margin-top: calc($base-spacing);
        border-radius: $base-border-radius;
        font-size: large;
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $base-border-radius * 2;

        @media only screen and (max-width: 900px) {
          height: 6rem;
        }

        @media only screen and (max-width: 600px) {
          font-size: medium;
          padding: calc($small-spacing);
          margin: calc($small-spacing) 0;
          height: 5.5rem;
        }
      }

      .red {
        background-color: #ff9d98;
      }

      .yellow {
        background-color: #ffe680;
      }

      .green {
        background-color: #a6ecb2;
      }
    }
  }
}
