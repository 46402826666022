#humpr {
  .mdc-dialog {
    .modal-intro {
      margin-bottom: 1em;
    }
    .gray {
      background-color: gray;

      .mdc-dialog__content,
      .mdc-dialog__title {
        color: white;
      }
    }
    .magenta {
      background-color: #d05edf;

      .mdc-dialog__content,
      .mdc-dialog__title {
        color: black;
      }
    }
  }
}
