#humpr {
  $humpr-orange: #f2bf42;
  $humpr-blue: #4db8ff;
  $humpr-gray: #aaaaaa;
  $humpr-magenta: #e848f4;

  $mobile-width-max: 599px;
  $tablet-width-min: 600px;

  img {
    max-width: 100%;
  }

  .humpr-logo {
    display: block;
    margin: auto;
    max-width: 7em;
    padding-top: 31vh;
  }

  .instruction-text {
    margin: auto;
    text-align: center;
    color: $humpr-orange;
  }

  .navbar-logo {
    display: block;
    margin: auto;
    height: 56px;
    padding-top: 3px;
    padding-left: 50px;
  }

  .dating-app-narration {
    float: right;
  }

  .audio-icon {
    cursor: pointer;
  }

  .dating-app-background {
    background: radial-gradient(gray, black);
    height: 110vh;
  }

  .dating-app-header {
    background: radial-gradient(gray, black);
    min-height: 60px;
    width: 100%;
    z-index: 999;
  }
  .profile-options {
    @media (max-width: $tablet-width-min) {
      column-count: 3;
    }
  }
  .profile-pic-option {
    width: calc(22.9166666667% - 1.2291666667em);
    float: left;
    margin-left: 1em;
    padding: 15px;
    padding-top: 10px;

    @media (max-width: $mobile-width-max) {
      width: 100%;
      padding: 10px 0 10px 0;
      margin: 0;
    }
  }

  .next-button-visible {
    float: right;
    text-decoration: none;
    margin: 5px;
  }

  .back-button {
    float: left;
    text-decoration: none;
    margin: 5px;
  }

  .next-button-invisible {
    display: none;
    float: right;
  }

  .dating-app-hilighted {
    box-shadow: inset 0 0 0 1px blue;
  }

  .screen-name-field {
    max-width: 50%;
    margin: auto;
    width: 100%;
  }

  .dating-app-radio-buttons {
    width: 19vw;
    margin-left: auto;
    margin-right: auto;

    input[type="radio"] {
      border: 0px;
      opacity: 1;
    }

    input[type="radio"] + label {
      padding-left: 7vw;
      font-size: 16px;
      letter-spacing: 0px;
      color: $humpr-blue;
    }

    input[type="radio"] + label:before {
      content: none;
    }

    input[type="radio"] + label:after {
      content: none;
    }
  }

  .profile-picture {
    width: calc(25% - 1.25em);
    float: left;
    margin-left: 1em;
  }

  .profile-information {
    width: calc(75% - 1.75em);
    float: left;
    margin-left: 1em;
  }

  .profile-home-button {
    display: none;
    font-size: 1.5em;
    float: right;
    padding: 10vw;
    padding-top: 0vw;
  }

  .profile-item,
  .screen-name,
  .age {
    font-weight: normal;
    font-size: 1em;
    border-radius: 10px;
    display: inline;
    color: white;
  }

  .clickable {
    border-radius: 10px;
    display: inline;
  }

  .profile-item-label {
    font-weight: bold;
    font-size: 1em;
    border-radius: 10px;
    display: inline;
    color: $humpr-blue;
  }

  .profiles-homepage {
    columns: 4;
    @media (max-width: $tablet-width-min) {
      column-count: 3;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .profile {
    padding: 15px;
    @media (max-width: $tablet-width-min) {
      padding: 10px 0 0 0;
      max-width: 30%;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  #gray {
    background-color: $humpr-gray;
  }

  #magenta {
    background-color: $humpr-magenta;
  }

  .risky {
    border: 2px solid $humpr-magenta;
  }

  .not-risky {
    border: 2px solid $humpr-gray;
  }

  .dating-app-summary {
    max-width: 50vw;
    margin: auto;
    color: white;
    ul {
      list-style-type: unset;
    }
    @media (max-width: $tablet-width-min) {
      max-width: 95vw;
    }
  }

  .dating-app-inner-list {
    padding-left: 5vw;
  }

  a.next-button-visible,
  a.next-button-invisible,
  a.profile-home-button,
  a.back-button {
    font-size: 20px;
    color: $humpr-orange;
    font-weight: bold;
    border: 3px solid $humpr-orange;
    border-radius: 25px;
    padding: 10px;
  }

  .sim-center-create-profile-name {
    font-size: 20px;
    color: $humpr-orange;
    text-align: left;
    padding-left: 25%;
  }

  .create-profile-name {
    display: grid;
  }

  .sim-center {
    font-size: 20px;
    color: $humpr-orange;
    text-align: center;
  }

  .dating-app-block {
    display: inline-flex;
    vertical-align: bottom;
    width: 15em;
  }

  .dating-app-radio-buttons input[type="radio"] + label {
    padding-left: 1.6vw;
  }

  .dating-app-label {
    color: $humpr-orange;
  }

  @media only screen and (max-width: 600px) {
    .dating-app-block {
      display: -webkit-inline-flex;
      vertical-align: bottom;
    }
  }
}
