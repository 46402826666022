$mobile-width-max: 599px;
$tablet-width-max: 979px;

body {
  margin: 0;
}

.shl {
  display: flex;

  .hidden {
    display: none !important;
  }

  .shl-sidebar {
    width: 25%;
    height: 100vh;
    overflow-y: visible;
    background-color: rgb(200, 200, 200);
    padding: 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    z-index: 2;

    .form-select {
      white-space: normal;
      text-overflow: ellipsis;
      margin-bottom: 1rem;
    }

    h3 {
      font-weight: bold;
    }
  }

  .shl-sidebar::-webkit-scrollbar {
    display: none;
  }

  .shl-map {
    display: grid;
    width: 75%;
    height: 100vh;
    text-align: center;
    align-content: center;
    z-index: 0;
    overflow-x: hidden;
  }
}

@media (max-width: $tablet-width-max) {
  .shl {
    display: flex;
    flex-direction: column;

    .shl-sidebar {
      width: calc(100% - 2rem);
      height: auto;
    }

    .shl-map {
      width: 100%;
      margin-top: 20%;
      margin-bottom: 2rem;
      height: auto;
    }
  }
}
