// Breakpoints
$large-screen: 900px;

// Line height
$base-line-height: 1.5;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: calc($base-spacing / 2);

// Border
$base-border-color: #ddd;
$base-border: 1px solid $base-border-color;

// Colors
$stop: #a6ecb2;
$continue: #ff9d98;

#condom-discontinuation {
  max-width: $large-screen;
  margin: auto;

  h1 {
    border-bottom: 1px solid black;
  }

  p {
    font-size: 1.25em;
  }

  .status {
    margin: calc($small-spacing / 2) auto;
    background-color: #a6ecb2;
    text-align: center;
    padding: calc($small-spacing / 2);
    border-radius: $base-border-radius;
    font-size: 1.125em;

    &.stop {
      background-color: $stop;
    }

    &.continue {
      background-color: $continue;
    }
  }

  #questions {
    margin: calc($small-spacing / 2) auto;
    border: $base-border;
    border-radius: $base-border-radius;

    .question {
      padding: calc($small-spacing / 4);

      &:not(:first-child) {
        border-top: $base-border;
      }

      .mdc-form-field {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}
