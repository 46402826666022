.serious_relationship_tools {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;

  .mb-3 {
    margin-bottom: 1rem !important;
  }

  .mr-3 {
    margin-right: 1rem !important;
  }

  .mt-3 {
    margin-top: 1rem !important;
  }

  .ml-0 {
    margin-left: 0 !important;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
}
